
import { Options, Vue } from "vue-class-component";
import { IGoal } from "@/types/Goal";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Datepicker from "@vuepic/vue-datepicker";
import Breadcrumb from "@/components/Breadcrumb.vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { formatDate_h, notify } from "@/services/helpers";

@Options({
  name: "NewGoal",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    Datepicker,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        name: yup
          .string()
          .required("Please provide a name for the goal.")
          .max(30, "Must not exceed 30 characters in length."),
        description: yup
          .string()
          .required("Please provide a description for the goal.")
          .max(100, "Must not exceed 100 characters in length."),
        setDate: yup
          .string()
          .required("Please provide a set date for the goal."),
        dueDate: yup
          .string()
          .required("Please provide a due date for the goal."),
      }),
    };
  },
  props: {},
  methods: {},
})
export default class NewGoal extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  newGoal: IGoal = {
    id: 0,
    name: "",
    description: "",
    setDate: "",
    dueDate: "",
    employeeId: 0,
  };
  crumbs: any = ["Dashboard", "Employees", "Goals", "New Goal"];
  loading = false;

  formatDate = formatDate_h;

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  async created() {
    this.newGoal.employeeId = +this.$route.params.id;
  }

  message = "";
  async save() {
    this.loading = true;
    if (new Date(this.newGoal.setDate) > new Date(this.newGoal.dueDate)) {
      notify(
        "Please ensure that the set date is earlier than the due date.",
        "Attention",
        "danger"
      );
      this.loading = false;
      return;
    }
    this.newGoal.dueDate = this.formatDate(this.newGoal.dueDate);
    this.newGoal.setDate = this.formatDate(this.newGoal.setDate);
    await this.workForceService
      .postOrPut<void>("/goals", this.newGoal as IGoal, undefined)
      .then((response: any) => {
        if (!response.isError) {
          this.loading = false;
          notify("Goal added successfully.", "Success", "success");
          this.goToRoute(`/admin/goals/${this.$route.params.id}`);
        } else {
          notify(response.errors, "Error", "danger");
          this.loading = false;
        }
      });
  }
}
